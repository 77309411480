import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import arrowIcon from './images/arrow.png';
import checkedIcon from './images/checked_icon.png';

import { profileSteps } from '../profileSteps';
import css from './ProfileCompletionStatus.module.css';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { accountTypes } from '../../../util/accountTypes';
import { calculatePercentages } from '../utils/profileCompletion';
import CompleteIcon from './images/complete_icon.svg';
import { AsideCardBlured } from '../../../components';

const ProfileCompletionStatusComponent = props => {
  const { currentStep, currentUser } = props;

  const groupedSteps = [
    {
      parentStep: profileSteps.profileDetails,
      childStep: profileSteps.profileAddress
    },
    {
      parentStep: profileSteps.profileDetails,
      childStep: profileSteps.profileIdentityDocument
    }
    ,
    {
      parentStep: profileSteps.companyProfile,
      childStep: profileSteps.companyAddress
    }
  ]

  const childStepOfParent = groupedSteps.find(g => g.childStep === currentStep)?.parentStep;
  const currentStepOrParent = childStepOfParent !== undefined ? childStepOfParent : currentStep;

  const calculatedProgress = calculatePercentages(currentUser);
  var firstItem = calculatedProgress.filter(s => s.step === currentStepOrParent);
  var remainingItems = calculatedProgress.filter(s => s.step !== currentStepOrParent).sort((a, b) => a.progress - b.progress);

  const profileProgress = firstItem.concat(remainingItems);

  return (
    <AsideCardBlured>

      <div className={css.horizontalWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id={"ProfileCompletionStatus.title"} />
        </h1>
        <img className={css.icon} src={checkedIcon} />
      </div>

      <p className={css.info}>
        <FormattedMessage id={"ProfileCompletionStatus.info"} />
      </p>

      {profileProgress.map((current) => (
        <div key={current.step} className={css.sectionWrapper}>
          <div className={css.horizontalWrapper}>
            <p className={currentStepOrParent === current.step ? css.sectionInfoCurrent : css.sectionInfo}>
              <FormattedMessage id={"ProfileSteps." + current.step} />
            </p>
            <img className={currentStepOrParent === current.step ? css.arrowCurrent : css.arrow} src={arrowIcon} />
          </div>
          <ProgressBar progress={current.progress} />
          <p className={current.progress === 100 ? css.sectionInfoCurrent : css.sectionInfo}>
            {current.progress === 0 ?
              <FormattedMessage id={"ProfileCompletionStatus.notStarted"} /> :

              <>
                {current.progress === 100 &&
                  (
                    <img className={css.completeIcon} src={CompleteIcon} />
                  )}
                <FormattedMessage id={"ProfileCompletionStatus.completed"} values={{ percent: current.progress }} />
              </>
            }
          </p>
        </div>
      ))}
    </AsideCardBlured>
  );
}

const ProfileCompletionStatus = compose(injectIntl)(ProfileCompletionStatusComponent);
ProfileCompletionStatus.displayName = 'ProfileCompletionStatus';

export default ProfileCompletionStatus;
